<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-sql ref="tableBalance" :query="sqlBalance" :footer="sqlBalance.footer" :model="balanceModel" :searchable="false"></a-table-f-sql>
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, popupMenu } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu],
  components: {},
  props: {
    height: { type: Number, default: 300 },
    data: Object,
  },
  data() {
    return {
      loading: false,
      sql: "",
    };
  },
  created() {},
  watch: {},
  computed: {
    balanceModel() {
      return [
        { name: "id", title: "id", type: "number", width: 30, sortable: true },
        { name: "account", title: "счет", type: "string", width: 50, sortable: true },
        { name: "date", title: "Дата", type: "datetime", width: 120, sortable: true },
        { name: "operation_id", title: "Операция", type: "select", dir: "operationTable", width: 200, sortable: true, style: "white-space: normal;" },
        { name: "namedoc", title: "Документ", type: "string", width: 200, sortable: true, style: "white-space: normal;" },
        { name: "value_cr", title: "Приход", type: "number", width: 70, sortable: true },
        { name: "value_db", title: "Расход", type: "number", width: 70, sortable: true },
        { name: "name1", title: "", type: "string", width: 200, sortable: true },
        { name: "name2", title: "", type: "string", width: 200, sortable: true },
        { name: "name3", title: "", type: "string", width: 200, sortable: true },
      ];
    },
    sqlBalance() {
      let id = this.data.id;
      let group = "";
      let order = "id desc";
      let where = "";
      if (!this.sql) {
        this.getSql();
      }
      let sql = this.sql;
      sql = sql.replaceAll(":id", id);
      let footer = [
        { name: "value_db", title: "Расход", type: "number" },
        { name: "value_cr", title: "Приход", type: "number" },
      ];
      let res = { sql, order, group, where, footer };
      return res;
    },
  },
  methods: {
    async getSql() {
      if (this.loading) return;
      this.loading = true;
      let response = await this.$axios.get("/report_sql/config");
      const obj = response?.data?.data || [];
      const sql = (obj.find((f) => f.name == "getObjectBalance") || {})?.sql;
      console.log(sql);
      this.sql = sql;
      //this.loading = !true;
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
